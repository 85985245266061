<template>
  <div id="footerContainer" class="disable-select">
    <div class="bg-white w-100 h-px-40 align-items-center border-top border-lightgray" :class="[ isMobileDevice === false || windowOrientation === 'portrait' || windowOrientation === null ? 'd-flex' : 'd-none d-md-flex' ]" v-if="$store.getters.getProduct === 'personal' && (typeof $route.query.disable_statusbar === 'undefined' || $route.query.disable_statusbar === 'false')">
      <!-- meeting is running status bar, always show status 'in conversation with ...' when meeting is started -->
      <div class="ml-2 ml-sm-3 text-truncate" v-if="$store.getters.getMeetingStarted" :style="{color: $store.getters.getMeeting.site.videochat_text_color }">
        {{ $t('FooterParticipant.In conversation with {participants}', {'participants': getRemoteMeetingParticipants}) }}
      </div>

      <!-- meeting status bar -->
      <div class="ml-2 ml-sm-3" v-else-if="$route.name === 'meeting' && $store.getters.isUINavigationEnabled === true && (typeof $route.query.disable_statusbar_text === 'undefined' || $route.query.disable_statusbar_text === 'false')">
        <template v-if="$store.getters.getStatus === 'online'">{{ $t('Footer.Available for calls') }}</template>
        <template v-else>{{ $t('Footer.Not available for calls') }}</template>
      </div>

      <!-- appointment status bar -->
      <div class="ml-2 ml-sm-3" v-else-if="$route.name === 'appointment' && (typeof $route.query.disable_statusbar_text === 'undefined' || $route.query.disable_statusbar_text === 'false')">
        <template v-if="switchingStatus === true">
          <template v-if="$store.getters.getStatus === 'online'">{{ $t('Footer.Available for calls') }}</template>
          <template v-else>{{ $t('Footer.Not available for calls') }}</template>
        </template>
        <template v-else>
          <template v-if="appointmentSent === true">{{ $t('Footer.Invitation has been sent') }}</template>
          <template v-else-if="appointmentChanged === true">{{ $t('Footer.Appointment has been changed') }}</template>
          <template v-else-if="editAppointment === true">{{ $t('Footer.Edit appointment') }}</template>
          <template v-else>{{ $t('Footer.Schedule an appointment') }}</template>
        </template>
      </div>

      <!-- waiting room status bar -->
      <div class="ml-2 ml-sm-3" v-else-if="$route.name === 'waiting_room' && (typeof $route.query.disable_statusbar_text === 'undefined' || $route.query.disable_statusbar_text === 'false')">
        <template v-if="switchingStatus === true">
          <template v-if="$store.getters.getStatus === 'online'">{{ $t('Footer.Available for calls') }}</template>
          <template v-else>{{ $t('Footer.Not available for calls') }}</template>
        </template>
        <template v-else-if="appointmentRemoved === true">{{ $t('Footer.Appointment is removed') }}</template>
        <template v-else-if="noScheduledAppointments === true">{{ $t('Footer.No appointments are scheduled') }}</template>
        <template v-else>{{ $t('Footer.Scheduled appointments') }}</template>
      </div>

      <div class="d-flex ml-auto">
        <!-- meeting counter -->
        <div class="d-flex mr-2 mr-sm-3" v-if="$route.name === 'meeting' && this.$store.getters.getMeeting !== false && (typeof $route.query.disable_timer === 'undefined' || $route.query.disable_timer === 'false')">
          {{ getMeetingTime }}
        </div>
        <tooltip-wrapper allowSpace="true" v-if="this.$store.getters.getMeetingStarted !== false" tabindex="0" @emitFunction="openDeviceSettingsDialog" :tooltipText="$t('Accessibility/Tooltip.Settings')">
          <div class="d-flex mr-2 mr-sm-3 font-size-px-25 align-items-center position-relative z-3">
            <font-awesome-icon :icon="['fas', 'gear']" class="cursor-pointer" />
          </div>
        </tooltip-wrapper>
      </div>
    </div>

    <!-- menu smaller screens -->
    <div class="d-flex d-lg-none h-px-40 bg-white w-100 align-items-center border-top border-lightgray justify-content-center font-size-rem-10 footer" v-if="$store.getters.getProduct === 'personal' && $store.getters.isUINavigationEnabled && this.$store.getters.userHasFeature('appointment') && (isMobileDevice === false || windowOrientation === 'portrait' || windowOrientation === null) && (typeof $route.query.disable_menu === 'undefined' || $route.query.disable_menu === 'false') && (typeof $route.query.disable_footer === 'undefined' || $route.query.disable_footer === 'false')">
      <router-link :to="$root.isAppReconnecting === true ? '' : { name: 'meeting' }" :class="[ $route.name === 'meeting' ? 'bg-secondary' : '', { 'disabled': $root.isAppReconnecting === true } ]" class="d-flex flex-row w-33 h-100 align-items-center justify-content-center" v-if="this.$store.getters.getUser.role !== 'assistent' && (typeof $route.query.disable_menuitem_meeting === 'undefined' || $route.query.disable_menuitem_meeting === 'false')">{{ $t('Footer.Meeting') }}</router-link>
      <router-link :to="($store.getters.getMeeting !== false && $store.getters.getMeetingStarted === false && $store.getters.getMeetingEnded === false) || $root.isAppReconnecting === true ? '' : { name: 'appointment' }" :class="[ $route.name === 'appointment' ? 'bg-secondary' : '', { 'disabled': ($store.getters.getMeeting !== false && $store.getters.getMeetingStarted === false && $store.getters.getMeetingEnded === false) || $root.isAppReconnecting === true } ]" class="d-flex w-33 h-100 align-items-center justify-content-center" v-if="typeof $route.query.disable_menuitem_appointment === 'undefined' || $route.query.disable_menuitem_appointment === 'false'">{{ $t('Footer.Appointment') }}</router-link>
      <router-link :to="($store.getters.getMeeting !== false && $store.getters.getMeetingStarted === false && $store.getters.getMeetingEnded === false) || $root.isAppReconnecting === true ? '' : { name: 'waiting_room' }" :class="[ $route.name === 'waiting_room' ? 'bg-secondary' : '', { 'disabled': ($store.getters.getMeeting !== false && $store.getters.getMeetingStarted === false && $store.getters.getMeetingEnded === false) || $root.isAppReconnecting === true } ]" class="d-flex w-33 h-100 align-items-center justify-content-center" v-if="typeof $route.query.disable_menuitem_waiting_room === 'undefined' || $route.query.disable_menuitem_waiting_room === 'false'">
        <template v-if="this.$store.getters.userHasFeature('waitingroom')">{{ $t('Footer.Waiting room') }}</template>
        <template v-else>{{ $t('Footer.Calendar') }}</template>
      </router-link>
    </div>

    <div class="d-flex bg-white w-100 h-px-65 border-top border-lightgray align-items-center font-size-rem-11 footer" v-if="typeof $route.query.disable_footer === 'undefined' || $route.query.disable_footer === 'false'">
      <!-- logo -->
      <div class="ml-3 align-items-center w-px-120" :class="[ isMobileDevice === false || windowOrientation === 'portrait' || windowOrientation === null || $store.getters.getProduct === 'direct' ? 'd-flex' : 'd-none d-md-flex' ]" v-if="typeof $route.query.disable_logo === 'undefined' || $route.query.disable_logo === 'false'">
        <img src="../assets/logo-weseedo.svg" alt="WeSeeDo logo" class="logo_footer margin-min-top-5" v-if="($store.getters.getUser === false || typeof $store.getters.getUser.entity.logo === 'undefined' || $store.getters.getUser.entity.logo === null || typeof $store.getters.getUser.entity.custom_domain === 'undefined' || $store.getters.getUser.entity.custom_domain !== true) && (hostname.includes('weseedo') || hostname.includes('localhost'))" />
        <img :src="$store.getters.getUser.entity.logo" class="logo_footer" alt="Logo" v-else-if="$store.getters.getUser !== false && typeof $store.getters.getUser.entity.logo !== 'undefined' && $store.getters.getUser.entity.logo !== null && typeof $store.getters.getUser.entity.custom_domain !== 'undefined' && $store.getters.getUser.entity.custom_domain === true" />
      </div>
      <!-- slider -->
      <tooltip-wrapper tabindex="0" @emitFunction="toggleOnlineStatus()" :tooltipText="onlineStatus ? $t('Accessibility/Tooltip.Toggle yourself offline') : $t('Accessibility/Tooltip.Toggle yourself online')">
        <div class="d-flex justify-content-center align-self-center align-items-center ml-2 ml-sm-3 mr-1 w-px-60">
          <label class="switch-footer my-0" v-if="$store.getters.getProduct === 'personal' && $store.getters.isUINavigationEnabled && $store.getters.getUser !== false && $store.getters.getUser.role !== 'assistent' && $root.isAppReconnecting === false && (typeof $route.query.disable_status_slider === 'undefined' || $route.query.disable_status_slider === 'false')">
            <input tabindex="-1" type="checkbox" v-model="onlineStatus">
            <span class="slider round"></span>
          </label>
        </div>
      </tooltip-wrapper>
      <!-- menu bigger screens -->
      <div class="h-px-64 text-center justify-content-center flex-grow-1 align-self-start ml-1 mr-2" :class="[ isMobileDevice === false || windowOrientation === 'portrait' || windowOrientation === null ? 'd-none d-lg-flex' : 'd-flex' ]" v-if="$store.getters.getProduct === 'personal' && $store.getters.isUINavigationEnabled && this.$store.getters.userHasFeature('appointment') && ($route.name !== 'meeting' || $store.getters.getMeeting === false || isMobileDevice === false || windowOrientation === 'landscape') && (typeof $route.query.disable_menu === 'undefined' || $route.query.disable_menu === 'false')">
        <router-link :to="$root.isAppReconnecting === true ? '' : { name: 'meeting' }" :class="[ $route.name === 'meeting' ? 'bg-secondary' : '', { 'disabled': $root.isAppReconnecting === true } ]" class="d-flex w-30 h-100 align-items-center justify-content-center" v-if="$store.getters.getUser.role !== 'assistent' && (typeof $route.query.disable_menuitem_meeting === 'undefined' || $route.query.disable_menuitem_meeting === 'false')">{{ $t('Footer.Meeting') }}</router-link>
        <router-link :to="($store.getters.getMeeting !== false && $store.getters.getMeetingStarted === false && $store.getters.getMeetingEnded === false) || $root.isAppReconnecting === true ? '' : { name: 'appointment' }" :class="[ $route.name === 'appointment' ? 'bg-secondary' : '', { 'disabled': ($store.getters.getMeeting !== false && $store.getters.getMeetingStarted === false && $store.getters.getMeetingEnded === false) || $root.isAppReconnecting === true } ]" class="d-flex w-33 h-100 align-items-center justify-content-center" v-if="typeof $route.query.disable_menuitem_appointment === 'undefined' || $route.query.disable_menuitem_appointment === 'false'">{{ $t('Footer.Appointment') }}</router-link>
        <router-link :to="($store.getters.getMeeting !== false && $store.getters.getMeetingStarted === false && $store.getters.getMeetingEnded === false) || $root.isAppReconnecting === true ? '' : { name: 'waiting_room' }" :class="[ $route.name === 'waiting_room' ? 'bg-secondary' : '', { 'disabled': ($store.getters.getMeeting !== false && $store.getters.getMeetingStarted === false && $store.getters.getMeetingEnded === false) || $root.isAppReconnecting === true } ]" class="d-flex w-33 h-100 align-items-center justify-content-center" v-if="typeof $route.query.disable_menuitem_waiting_room === 'undefined' || $route.query.disable_menuitem_waiting_room === 'false'">
          <template v-if="this.$store.getters.userHasFeature('waitingroom')">{{ $t('Footer.Waiting room') }}</template>
          <template v-else>{{ $t('Footer.Calendar') }}</template>
        </router-link>
        <router-link v-if="$store.getters.getMeetingStarted === true && $store.getters.siteHasFeature('signicat') && $store.getters.dealerHasFeature('signicat')" :to="''" class="d-flex w-30 h-100 align-items-center justify-content-center" @click.native="startIDProcess">{{ $t('Footer.Identification') }}</router-link>
      </div>
      <div class="d-flex ml-auto justify-content-end" :class="{ 'min-w-px-170' : $store.getters.getMeeting !== false && isMobileDevice === true && windowOrientation === 'landscape' }">
        <!-- meeting counter -->
        <div class="mr-2 mr-sm-3" :class="[ (isMobileDevice === false || windowOrientation === 'portrait' || windowOrientation === null || (typeof $route.query.disable_timer !== 'undefined' && $route.query.disable_timer === 'true')) && $store.getters.getProduct === 'personal' ? 'd-none' : 'd-flex d-md-none' ]" v-if="$route.name === 'meeting' && this.$store.getters.getMeeting !== false">
          {{ getMeetingTime }}
        </div>
        <!-- full agent name -->
        <div class="mr-2 mr-sm-3" :class="[ isMobileDevice === false || windowOrientation === 'portrait' || windowOrientation === null || $store.getters.getProduct === 'direct' ? 'd-none d-sm-flex' : 'd-none d-lg-flex' ]" v-if="typeof $route.query.disable_name === 'undefined' || $route.query.disable_name === 'false'">
          {{ fullName }}
        </div>
        <!-- firstname agent -->
        <div class="mr-2 mr-sm-3" :class="[ isMobileDevice === false || windowOrientation === 'portrait' || windowOrientation === null || $store.getters.getProduct === 'direct' ? 'd-flex d-sm-none' : 'd-none d-md-flex d-lg-none' ]" v-if="typeof $route.query.disable_name === 'undefined' || $route.query.disable_name === 'false'">
          {{ firstName }}
        </div>

        <!-- div class="d-flex ml-1 font-size-px-25" v-if="this.$store.getters.getMeetingStarted !== false">
          <router-link :to="''"  @click.native="openDeviceSettingsDialog">
            <font-awesome-icon :icon="['fas', 'gear']" class="cursor-pointer mr-2 mr-sm-3" />
          </router-link>
        </div -->
        <!--div class="d-flex mr-2 mr-sm-3 font-size-px-25 align-items-center" v-if="!($route.name === 'meeting' && this.$store.getters.getMeeting !== false) && $store.getters.isUINavigationEnabled && (typeof $route.query.disable_logout === 'undefined' || $route.query.disable_logout === 'false')"-->

        <!--
        <div class="d-flex ml-1 mr-2 mr-sm-3 font-size-px-25" v-if="$store.getters.isUINavigationEnabled">
          <font-awesome-icon :icon="['fal', 'question-square']" class="cursor-pointer mr-3 mr-sm-4" />
        </div>
        -->
        <tooltip-wrapper v-if="$store.getters.isUINavigationEnabled && (typeof $route.query.disable_logout === 'undefined' || $route.query.disable_logout === 'false')" tabindex="0" @emitFunction="$router.push({'name': 'logout'})" :tooltipText="$t('Accessibility/Tooltip.Log out')">
          <div class="d-flex mr-2 mr-sm-3 font-size-px-25 align-items-center">
            <font-awesome-icon :icon="['fal', 'right-from-bracket']" class="cursor-pointer" />
          </div>
        </tooltip-wrapper>
      </div>
    </div>
  </div>
</template>

<script>
import TooltipWrapper from './TooltipWrapper.vue'

export default {
  name: 'Footer',
  data () {
    return {
      fullName: '',
      firstName: '',
      onlineStatus: this.$store.getters.getStatus === 'online',
      statusBarTimeout: null,
      switchingStatus: false,
      editAppointment: false,
      appointmentSent: false,
      appointmentChanged: false,
      appointmentRemoved: false,
      noScheduledAppointments: false,
      isMobileDevice: window.DetectRTC.isMobileDevice,
      meetingCounter: 0,
      meetingCounterInterval: null,
      hostname: '',
      meetingRemoteParticipants: '',
      windowOrientation: typeof window.innerWidth !== 'undefined' && typeof window.innerHeight !== 'undefined' ? window.innerWidth > window.innerHeight ? 'landscape' : 'portrait' : null
    }
  },
  components: {
    TooltipWrapper
  },
  methods: {
    openDeviceSettingsDialog () {
      // this.$router.push({ name: 'device_settings' })
      this.$webrtc.toggleDeviceSettingsPane()
    },
    setAppointmentSent (sent) {
      this.appointmentSent = sent
    },
    setAppointmentChanged (changed) {
      this.switchingStatus = false
      this.appointmentChanged = changed
    },
    setAppointmentRemoved () {
      this.switchingStatus = false
      this.appointmentRemoved = true

      clearTimeout(this.statusBarTimeout)
      this.statusBarTimeout = null

      this.statusBarTimeout = window.setTimeout(() => {
        this.appointmentRemoved = false
      }, 3000)
    },
    setEditAppointment (edit) {
      this.editAppointment = edit
    },
    setNoScheduledAppointments (scheduled) {
      this.noScheduledAppointments = scheduled
    },
    onStatusChanged (args) {
      this.onlineStatus = args.status === 'online'
    },
    setMeetingCounter () {
      if (this.$store.getters.getMeetingStarted === true) {
        this.meetingCounterInterval = setInterval(() => {
          this.meetingCounter++
        }, 1000)
      } else {
        clearInterval(this.meetingCounterInterval)
        this.meetingCounterInterval = null
        this.meetingCounter = 0
      }
    },
    changeWindowOrientation (orientation) {
      console.log('Orientation changed in footer', orientation)
      this.windowOrientation = orientation
    },
    startIDProcess () {
      this.$webrtc.triggerIdentification()
    },
    toggleOnlineStatus () {
      this.onlineStatus = !this.onlineStatus
    }
  },
  computed: {
    watchUser () {
      return this.$store.getters.getUser
    },
    getRemoteMeetingParticipants () {
      let participants = this.$store.getters.getRemoteMeetingParticipants

      if (participants === false) {
        return
      }

      participants = Object.values(participants).map((participant) => {
        if (participant === 'Bezoeker') {
          return this.$t('Footer.Visitor')
        } else {
          return participant
        }
      })

      if (participants.length === 1) {
        return participants[0]
      } else {
        const lastParticipant = participants.pop()
        return participants.join().replace(/,/g, ', ') + ' ' + this.$t('MeetingRoom.and') + ' ' + lastParticipant
      }
    },
    getMeetingTime () {
      let time = new Date(this.meetingCounter * 1000).toISOString().substr(11, 8)

      // hours are 0, remove starting zero and double point
      if (Number(time.substr(0, 2)) === 0) {
        time = time.substr(3)
      // hours lower than 10, remove starting zero
      } else if (Number(time.substr(0, 2)) < 10) {
        time = time.substr(1)
      }

      return time
    },
    meetingStarted () {
      return this.$store.getters.getMeetingStarted
    }
  },
  watch: {
    watchUser () {
      this.fullName = this.$root.setFullName(this.$store.getters.getUser)
    },
    onlineStatus () {
      if ((this.$store.getters.getStatus === 'online') !== this.onlineStatus) {
        this.$webrtc.setStatus(this.onlineStatus ? 'online' : 'offline', this.$store.getters.getMeeting === false && this.$root.emitStatus)

        // status is already emitted, make sure it will emit later in the timeout
        if (this.$root.emitStatus === false) {
          this.$root.emitDone = false
        }

        this.$root.emitStatus = false // dont emit status directly when clicking again

        clearTimeout(this.$root.emitTimeout)
        this.$root.emitTimeout = null

        this.$root.emitTimeout = setTimeout(() => {
          // only emit status after 3 seconds when clicking twice and meeting is not started
          if (this.$root.emitDone === false && this.$store.getters.getMeeting === false) {
            this.$webrtc.setStatus(this.onlineStatus ? 'online' : 'offline', true, false, false)
          }

          this.$root.emitDone = true // emit is now done
          this.$root.emitStatus = true // now we can emit again directly on changing status the next time
        }, 3000)

        this.switchingStatus = true
        this.appointmentRemoved = false

        clearTimeout(this.statusBarTimeout)
        this.statusBarTimeout = null

        this.statusBarTimeout = window.setTimeout(() => {
          this.switchingStatus = false
        }, 3000)
      }
    },
    meetingStarted () {
      // just in case mounted is later, it will still watch the store meetingStarted change
      this.setMeetingCounter()
    }
  },
  destroyed () {
    this.$eventBus.$off('setAppointmentSent', this.setAppointmentSent)
    this.$eventBus.$off('setAppointmentChanged', this.setAppointmentChanged)
    this.$eventBus.$off('setAppointmentRemoved', this.setAppointmentRemoved)
    this.$eventBus.$off('setEditAppointment', this.setEditAppointment)
    this.$eventBus.$off('setNoScheduledAppointments', this.setNoScheduledAppointments)
    this.$eventBus.$off('changeWindowOrientation', this.changeWindowOrientation)
    this.$eventBus.$off('startIDProcess', this.startIDProcess)

    this.$webrtc.EventBus.$off('onStatusChanged', this.onStatusChanged)

    clearInterval(this.meetingCounterInterval)
  },
  mounted () {
    this.fullName = this.$root.setFullName(this.$store.getters.getUser)
    this.firstName = this.$root.setFirstName(this.$store.getters.getUser)

    this.$eventBus.$on('setAppointmentSent', this.setAppointmentSent)
    this.$eventBus.$on('setAppointmentChanged', this.setAppointmentChanged)
    this.$eventBus.$on('setAppointmentRemoved', this.setAppointmentRemoved)
    this.$eventBus.$on('setEditAppointment', this.setEditAppointment)
    this.$eventBus.$on('setNoScheduledAppointments', this.setNoScheduledAppointments)
    this.$eventBus.$on('changeWindowOrientation', this.changeWindowOrientation)
    this.$eventBus.$on('startIDProcess', this.startIDProcess)

    this.$webrtc.EventBus.$on('onStatusChanged', this.onStatusChanged)

    this.setMeetingCounter()

    this.hostname = window.location.hostname
  }
}
</script>

<style lang="scss" scoped>
</style>
